import { type ArticleProps } from '@pretto/website/src/templates/blocs/rows/rowMedia/RowMediaContainer'

type FilterArticlesProps = {
  category: string
  articlesProps: ArticleProps
}

export const filterArticles = ({ category, articlesProps }: FilterArticlesProps) => {
  const filteredArticles = articlesProps.articles.nodes.filter(article =>
    article.categories.nodes.some(cat => cat.slug === category)
  )

  const filteredCategoryName = articlesProps.articlesCategory.nodes.filter(cat => cat.slug === category)[0].name

  return { articles: filteredArticles, articlesCategory: filteredCategoryName }
}
