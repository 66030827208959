import { slugify } from '@pretto/website/src/templates/blocs/lib/slugify'
import { CoverMediaProps } from '@pretto/website/src/templates/blocs/rows/rowMedia/components/coverMedia/CoverMedia'
import { ThumbnailMediaProps } from '@pretto/website/src/templates/blocs/rows/rowMedia/components/thumbnailMedia/ThumbnailMedia'

import { useState } from 'react'

import * as S from './RowMedia.styles'

export interface RowMediaProps {
  articles: ThumbnailMediaProps[]
  coverArtcile: CoverMediaProps
  isColored?: boolean
  isHero?: boolean
  maxArticlesDisplayed?: number
  suptitle?: string
  title: string
}

const MORE_ATICLES = "Voir plus d'articles"

export const RowMedia: React.FC<RowMediaProps> = ({
  articles,
  coverArtcile,
  isColored,
  isHero,
  maxArticlesDisplayed = 6,
  suptitle,
  title,
  ...props
}) => {
  const [hideArticles, setHideArticles] = useState<boolean>(
    maxArticlesDisplayed ? articles.length > maxArticlesDisplayed : false
  )

  const articlesToDisplay = hideArticles ? articles.slice(0, maxArticlesDisplayed) : articles

  const handleMoreArticlesClick = () => {
    setHideArticles(false)
  }

  return (
    <S.NewBlocRow isColored={isColored} {...props}>
      {suptitle && !isHero && <S.SupTitle> {suptitle}</S.SupTitle>}

      <S.Title id={slugify(title)} $isHero={isHero}>
        {title}
      </S.Title>

      <S.CoverMedia {...coverArtcile} />

      <S.Articles>
        {articlesToDisplay.map((article, index) => (
          <S.ThumbnailMedia key={index} {...article} />
        ))}
      </S.Articles>

      {hideArticles && (
        <>
          <S.MoreArticles onClick={handleMoreArticlesClick}>
            <S.MoreArticlesText>{MORE_ATICLES}</S.MoreArticlesText>
            <S.Picto />
          </S.MoreArticles>
          <S.MoreAticlesButton onClick={handleMoreArticlesClick}>{MORE_ATICLES}</S.MoreAticlesButton>
        </>
      )}
    </S.NewBlocRow>
  )
}
