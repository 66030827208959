import { useBreakpoint } from '@pretto/bricks/assets/utility'
import { temporal } from '@pretto/bricks/core/utility/temporal'

import { RowMedia } from '@pretto/website/src/templates/blocs/rows/rowMedia/components/rowMedia/RowMedia'

import { filterArticles } from './lib/filterArticles'

export interface ArticleProps {
  articles: {
    nodes: [
      {
        uri: string
        title: string
        modified: string
        date: string
        postTypeMeta: {
          highlightedimage: string
        }
        categories: { nodes: [{ slug: string }] }
      },
    ]
  }
  articlesCategory: {
    nodes: [
      {
        name: string
        slug: string
      },
    ]
  }
}

export interface RowMediaContainerProps {
  articlesProps: ArticleProps
  category: string
  isColored?: boolean
  isHero?: boolean
  suptitle?: string
  title: string
}

const MINIMUM_ARTICLES = 4

export const RowMediaContainer: React.FC<RowMediaContainerProps> = ({
  articlesProps,
  category,
  isColored = false,
  suptitle,
  title,
  ...props
}) => {
  const articlesFiltered = filterArticles({
    category,
    articlesProps,
  })

  if (articlesFiltered.articles.length < MINIMUM_ARTICLES) {
    if (!articlesFiltered.articles.length) {
      throw new Error(`No articles found in RowMediaContainer for ${articlesFiltered.articlesCategory} category`)
    }

    throw new Error(`Not enough articles in RowMediaContainer for ${articlesFiltered.articlesCategory} category`)
  }

  const { breakpoint } = useBreakpoint()

  const rowMediaProps = {
    articles: articlesFiltered.articles.slice(1).map(({ date, postTypeMeta: { highlightedimage }, title, uri }) => ({
      date: temporal(date).format('D MMMM YYYY'),
      hoverText: `Lire l'article`,
      image: highlightedimage,
      title,
      url: uri,
    })),
    coverArtcile: {
      category: articlesFiltered.articlesCategory,
      color: isColored ? 'neutral5' : 'neutral4',
      date: temporal(articlesFiltered.articles[0].date).format('D MMMM YYYY'),
      hoverText: `Lire l'article`,
      image: articlesFiltered.articles[0].postTypeMeta.highlightedimage,
      title: articlesFiltered.articles[0].title,
      url: articlesFiltered.articles[0].uri,
    },
    isColored,
    maxArticlesDisplayed: breakpoint === ('desktop' || 'laptop') ? 6 : breakpoint === 'tablet' ? 4 : 3,
    suptitle,
    title,
    ...props,
  }
  return <RowMedia {...rowMediaProps} />
}
